#root {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.bgImage{
  background-image: url('./assets/partsSearchImage.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
}

.bgImagePE{
  background-image: url('./assets/productInqueryImage.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: start;
}

.peMargin{
  margin-left: 34rem;
}

.bgImageCU{
  background-image: url('./assets/contactusImage.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
}

.contactusTextwidth{
  max-width: 42rem;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  padding: 1.9rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: contain;
}

.mySwiperMainCarosule img{
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.swiperWidthAboutus{
  width: 100%;
  display: block;
  border-radius: 10px;
  object-fit: contain;
}

.mySwiperAboutUs{
  max-width: 35rem;
  height: 20rem;
  display: flex;
  align-items: center;
  border: 2px solid rgba(128, 128, 128, 0.497);
}

.globalMargin{
  margin: 0px 4rem !important;
  padding: 0px !important;
}

.visionCss{
  background-color: #EF4444;
  padding: 1rem;
}

tbody {
  max-height: 20rem !important;
  overflow-y: auto !important;
  /* display: block; */

}

.maxBTN{
  max-width: 12rem !important;
}


@media (max-width: 600px) {
  .globalMargin {
    margin: 0px !important;
  padding: 0px !important;
  }

  .mobileHeightCards{
    height: 34rem;
  }

  .mobileTextMargin{
    margin-top: 18rem !important;
    margin-bottom: 1rem !important;
  }

  .mobileHeightCardsContactUs{
    height: 38rem;
  }

  .mobileTextMarginContactUs{
    margin-top: 16rem !important;
  }

  .mgTop{
    margin-top: 4.5rem !important;
  }

  .marginRight8{
    margin-right: 0.5rem !important;
  }
}


@media (min-width: 601px) and (max-width: 900px) {
  .bgImage {
    background: transparent;  
  }
}