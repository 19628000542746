@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .card {
      background-color: theme('colors.white');
      border-radius: theme('borderRadius.lg');
      padding: theme('spacing.6');
      box-shadow: theme('boxShadow.xl');
    }
  }